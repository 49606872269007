import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import './RegistrationForm.css';
import { ModalSuccess, ModalError } from '../Modals/AlertModal';

function RegistrationForm() {
	const [email, setEmail] = useState('');
	const [validated, setValidated] = useState(false);
	const [emailInvalid, setEmailInvalid] = useState(true);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const mailer = "0bbfcb29d81b1d872c42318f8e8b1ed2"
	const proxy = 'https://cors.f1deltatime.com/';
	const emailTest = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

	let handleEmailChanged = (e) => {
		setEmail(e.target.value)

		setEmailInvalid(!emailTest.test(e.target.value));
	}

	let handleSubscribe = async (e) => {
		e.preventDefault();

		let form = e.currentTarget

		if (form.checkValidity() === false) {
			e.stopPropagation();
			return;
		}

		setValidated(true);
		const postData = await fetch(proxy + 'https://api.mailerlite.com/api/v2/groups/76480360/subscribers', {
			method: 'POST',
			body: JSON.stringify({"email": email}),
			headers: {
				"Content-type": "application/json; charset=UTF-8",
				// "X-MailerLite-ApiKey": process.env.REACT_APP_MAILERLITE_API_KEY
				"X-MailerLite-ApiKey": mailer
			}
		}).catch(err => {
			console.log('Error subscribing to Mailerlite', err)
		})

		const result = await postData.json();
		
		if(!result.error) {
			setShowSuccessModal(true);
			setShowErrorModal(false);
		} else {
			setShowErrorModal(true);
			setShowSuccessModal(false);
		}

		setValidated(false);
		setEmailInvalid(true);
	}

	let handleCloseModal = () => {
		setShowSuccessModal(false);
		setShowErrorModal(false);
		setEmail('');
	}

	return (
		<>
			<div id="registration-form">
				<h1>Farm your NFTs!</h1>
				<p><b><small className="tagline">Discover our exclusive catalog of rewards</small></b></p>
				<Form noValidate validated={validated} onSubmit={handleSubscribe}>
					<Form.Group>
						<Form.Control 
							type="email" 
							size="md" 
							id="form-email" 
							placeholder="Enter your email to subscribe" 
							value={email}
							onChange={handleEmailChanged}
							required/>
						<Form.Control.Feedback type="invalid">
							Please provide a valid email.
						</Form.Control.Feedback>
					</Form.Group>
						
					<Button id="register-button" type="submit" disabled={emailInvalid}>SUBSCRIBE</Button>
					<p>Subscribe now and get an early bird reward!</p>
				</Form>
			</div>	

			<ModalSuccess email={email} show={showSuccessModal} handleClose={handleCloseModal} />
			<ModalError email={email} show={showErrorModal} handleClose={handleCloseModal} />
		</>
	);
}

export { RegistrationForm };