import React from 'react';
import { Row, Col, Container} from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ReactComponent as DiscordIcon } from '../../assets/icons/icon-discord.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/icon-facebook.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/icon-twitter.svg';
import AnimocaLogo from './../../assets/images/animoca-brands-logo.svg';
import NiftyLogo from './../../assets/images/nifty-white-logo.png';
import './Footer.css';

function Footer() {
	const socialMediaLinks = {
		"facebook": "https://www.facebook.com/Nifty-Farm-107053050785637/?modal=admin_todo_tour",
		"discord": "https://discordapp.com/invite/CtGfXN",
		"twitter": "https://twitter.com/FarmNifty"
	}

	return (
		<div id="footer">
			<Container>
				<div className="footer-top">
					<div className="footer-main-nav">
						<ul>
						      	<AnchorLink offset={ () => 100 } href="#home">Home</AnchorLink>
						      	<AnchorLink offset={ () => 100 } href="#about">About</AnchorLink>
								<AnchorLink offset={ () => 96 } href="#games">Games</AnchorLink>
						      	<AnchorLink offset={ () => 100 } href="#faq">FAQ</AnchorLink>
						</ul>
					</div>
					<div className="footer-social">
						<ul>
							<li><a href={ socialMediaLinks.facebook } target="_blank" rel="noopener noreferrer"><FacebookIcon /></a></li>
							<li><a href={ socialMediaLinks.discord } target="_blank" rel="noopener noreferrer"><DiscordIcon /></a></li>
							<li><a href={ socialMediaLinks.twitter } target="_blank" rel="noopener noreferrer"><TwitterIcon /></a></li>
						</ul>
					</div>
					<div className="copyright">
						<span>Copyright &copy; 2019 ANIMOCA BRANDS CORPORATION LIMITED, ALL RIGHTS RESERVED</span>
						<span>&copy; NIFTY FARM. ALL RIGHTS RESERVED</span>
					</div>	
				</div>	

				<div className="footer-bottom">
					<Row>
						<Col>
							<ul className="footer-logos">
								<li><a href="https://www.animocabrands.com/" target="_blank" rel="noopener noreferrer"><img src={ AnimocaLogo } alt="Animoca Brands" style={{ maxWidth: "16%" }} /></a></li>
								<li><a href="/"><img src={ NiftyLogo } alt="Nifty Farm" style={{ maxWidth: "18%" }} /></a></li>
								<li><a href="#">Privacy Policy</a></li>
							</ul>
						</Col>
					</Row>
				</div>
			</Container>	
		</div>
	)
}

export {Footer};