import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { RegistrationForm } from '../components/RegistrationForm/RegistrationForm';
import { Floater} from '../components';
import SantaImg from '../assets/images/santa.png';
import CornLeft from '../assets/images/corn-left.png';
import CornRight from '../assets/images/corn-right.png';
import CarrotLeft from '../assets/images/carrot-left.png';
import CarrotRight from '../assets/images/carrot-right.png';
import Raddish from '../assets/images/raddish.png';
import RaddishRight from '../assets/images/raddish-right.png';
import EggplantRight from '../assets/images/eggplant-right.png';
import EggplantRightNoBG from '../assets/images/eggplant-right-no-background.png';
import RaddishNoBG from '../assets/images/raddish-no-background.png';
import SantaMobileImg from '../assets/images/santa-mobile.png';
import about from '../assets/images/nifty-farm-about.png';
import f1deltatime from '../assets/images/f1-deltatime.png';
import stryking from '../assets/images/stryking.png';
import sandbox from '../assets/images/the-sandbox.png';
import './Home.css';
import './deco.css';

function Home() {
	return (
	<>	
		<div id="home">
			<section id="registration">
				<div id="farm-your-nfts">
					<div id="santa-mobile" className="mobile">
						<img alt="Santa" src={SantaMobileImg} />
					</div>
					<div id="santa-icons"></div>
					<RegistrationForm />
					<div id="santa-desktop">
						<img id="santa-image" alt="Santa" src={SantaImg} />
					</div>
				</div>
				<img className="floating-icons corn-1" alt="Santa" src={CornLeft} />
				<img className="floating-icons raddish-1" alt="Santa" src={RaddishRight} />
				<img className="floating-icons eggplant-1" alt="Santa" src={EggplantRight} />
				<img className="floating-icons carrot-1" alt="Santa" src={CarrotLeft} />
				{new Array(10).fill(0).map((x, id) => {return <Floater key={id} span={250} leftOnly/>})}
			</section>	

			<section id="about">
				<Container>
					<div id="inner-container">
					<div id="about-text">
						<h2>Nifty Farm is...</h2>
						{/* <p className="sub-title">Tagline</p> */}

						<p className="about-desc text-justify">Nifty Farm helps you to use the processing 
						power from your idle computer. In return for this computation, Nifty Farm will 
						reward you by giving you access to a range of rewards for the most popular blockchain 
						NFT projects. It is very simple, you just need to launch Nifty Farm and it will do all the work for you!</p>

						<a className="know-more-button" href="mailto:f1deltatime@animocabrands.com">KNOW MORE</a>	
					</div>
					<div className="about-image"><img src={about} /></div>
					</div>
				</Container>
					
				<img className="floating-icons corn-2" alt="Santa" src={CornRight} />
				<img className="floating-icons eggplant-2" alt="Santa" src={EggplantRight} />
				<img className="floating-icons eggplant-4" alt="Santa" src={EggplantRight} />
				{new Array(16).fill(0).map((x, id) => {return <Floater key={id} span={100} whiteColors/>})}
			</section>

			<section id="games">
				<Container>
					<h2>Games</h2>
					<Row id="game-logos">
						<Col lg="3" md="4" sm="12"><a href="https://www.sandbox.game/" target="_blank" rel="noopener noreferrer" className="logo-spacer">
							<div className="logo-wrapper"><img src={sandbox} /></div>
						</a></Col>
						<Col lg="3" md="4" sm="12"><a href="https://www.f1deltatime.com/" target="_blank" rel="noopener noreferrer" className="logo-spacer">
							<div className="logo-wrapper"><img src={f1deltatime} /></div>
						</a></Col>
						<Col lg="3" md="4" sm="12"><a href="https://presale.stryking.io/" target="_blank" rel="noopener noreferrer" className="logo-spacer">
							<div className="logo-wrapper"><img src={stryking} /></div>
						</a></Col>
					</Row>
				</Container>
			</section>

			<section id="faq">
				<Container>
					<h2 className="text-center">FAQ</h2>
					<p className="sub-title text-center mb-5">What would you like to know?</p>
					<Row>
						<Col lg="4" sm="12" xs="12">
							<div className="faq-box">
								<h4>What is Blockchain?</h4>

								<p>Blockchain is a different type of database, think of it as a record that cannot be changed. 
								Because of this, it can be used to prove ownership of the digital content, while also proving the authenticity of such content.</p>
							</div>
						</Col>
						<Col lg="4" sm="12" xs="12">
							<div className="faq-box">
								<h4>What are Non-Fungible Tokens [NFTs]?</h4>

								<p>Non-Fungible Tokens are uniquely identifiable assets on a blockchain. They often come with a picture and a set of attributes 
									and you can think of them as tradable virtual goods. They are useful for different purposes, 
									but one of the most exciting is when they represent in-game assets.</p>
							</div>
						</Col>
						<Col lg="4" sm="12" xs="12">
							<div className="faq-box">
								<h4>How to get onboard?</h4>

								<p>Nifty Farm will be released soon! Details will be provided later but we promise you one thing: 
									it is going to be very easy to use. Meanwhile, we would be delighted to have you as an early subscriber, 
									so please register your email. As an early subscriber, a nice surprise will be waiting for you at the launch date!</p>
							</div>
						</Col>
					</Row>
				</Container>

				<img className="floating-icons corn-3" alt="Santa" src={CornLeft} />
				<img className="floating-icons raddish-3" alt="Santa" src={RaddishNoBG} />
				<img className="floating-icons raddish-5" alt="Santa" src={Raddish} />
				<img className="floating-icons carrot-5" alt="Santa" src={CarrotRight} />
				<img className="floating-icons eggplant-5" alt="Santa" src={EggplantRightNoBG} />
				
				{new Array(6).fill(0).map((x, id) => {return <Floater key={id} span={60}/>})}
			</section>
		</div>
	</>	
	);
}

export {Home};