import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './AlertModal.css';

function ModalSuccess (props) {
	return (		
		<Modal show={props.show} onHide={props.handleClose} id="alert-modal">
		  	<Modal.Header closeButton />
	
		  	<Modal.Body className="text-center">
		    	<h2>Success!</h2>

		    	<p>Your subscription has been successfully confirmed.</p>
		    	<p>Please check <span className="modal-email-text">{props.email}</span></p>

		    	<Button className="modal-btn mt-4" onClick={props.handleClose}>OK</Button>
		  	</Modal.Body>
		</Modal>
	)
}

function ModalError (props) {
	return (		
		<Modal show={props.show} onHide={props.handleClose} id="alert-modal">
		  	<Modal.Header closeButton />
	
		  	<Modal.Body className="text-center">
		    	<h2 className="text-danger">Error!</h2>

		    	<p>Something went wrong. Please try again later</p>
		    	<Button className="modal-btn mt-4" onClick={props.handleClose}>Close</Button>
		  	</Modal.Body>
		</Modal>
	)
}

export { ModalSuccess, ModalError };