import React from 'react';
import './Floater.css';
import circle from './circle.png';
import triangle from './triangle.png';

function Floater(props) {
    let type, size, side, side_offset, top_offset, rotate;

    if(props && props.leftOnly) {
        side = 'left'
    } else {
        side = Math.random() >= 0.5 ? 'left' : 'right';
    }

    type = Math.random() >= 0.5 ? 'circle' : 'triangle';
    size = randomRange(32, 16);
    side_offset = randomRange(props.span || 50, 20);
    top_offset = randomRange(10, 90);
    rotate = Math.random() * 180;

    let style = {
        width: `${size}px`,
        height: `${size}px`,
        transform: `rotate(${rotate}deg)`,
        top: `${top_offset}%`,
        position: 'absolute'
    }

    if(side === 'left') {
        style['left'] = `${side_offset}px`
        
    } else {
        style['right'] = `${side_offset}px`
    }

    return (<span className='floater' style={style}><img src={type === 'circle' ? circle : triangle} alt="decoration"/></span>)
}

function randomRange(min, max) {
    return Math.random() * (max - min) + min;
}

export {Floater};