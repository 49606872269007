import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Home } from './pages/Home';
import { Footer } from './components/Footer/Footer';
import { Navigation } from './components/Header/Navigation';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: "GTM-M683L3H",
    auth: process.env.REACT_APP_GTM_AUTH,
    preview: process.env.REACT_APP_GTM_PREVIEW
}
  
TagManager.initialize(tagManagerArgs)

class App extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route path="*" component={Navigation}/>
                </Switch>
                <Switch>
                    <Route exact path='/' component={Home}/>
                </Switch>
                <Footer />
            </Router>
        );
    }
}

export default App;
