import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { ReactComponent as DiscordIcon } from '../../assets/icons/icon-discord.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/icon-facebook.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/icon-twitter.svg';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import logo from './../../logo.png';
import './Navigation.css';

function Navigation() {
	const [ activeLink, setActiveLink ] = useState('');
	const [ navExpanded, setNavExpanded] = useState(false)


	const socialMediaLinks = {
		"facebook": "https://www.facebook.com/Nifty-Farm-107053050785637/?modal=admin_todo_tour",
		"discord": "https://discordapp.com/invite/CtGfXN",
		"twitter": "https://twitter.com/FarmNifty"
	}

	const handleNavLinkClicked = (ref) => {
		setActiveLink(ref)
		setNavExpanded(false)
	}

	return (
		<Navbar id="header" variant="light" fixed="top" expand="lg" expanded={navExpanded} onToggle={setNavExpanded} collapseOnSelect>
				<Navbar.Toggle aria-controls="navbar-menu" />
				<Navbar.Brand className="logo" href="/">
					<img src={logo} alt="Nifty Farm Logo" />
				</Navbar.Brand>
				<Navbar.Collapse >
					<Nav className="nav-links">
						<AnchorLink onClick={ () => handleNavLinkClicked('home') } offset={ () => 100 } href="#home" className={ activeLink === '' || activeLink === 'home' ? 'active' : '' }>Home</AnchorLink>
						<AnchorLink onClick={ () => handleNavLinkClicked('about') } offset={ () => 100 } href="#about" className={ activeLink === 'about' ? 'active' : '' }>About</AnchorLink>
						<AnchorLink onClick={ () => handleNavLinkClicked('games') } offset={ () => 96 } href="#games" className={ activeLink === 'games' ? 'active' : '' }>Games</AnchorLink>
						<AnchorLink onClick={ () => handleNavLinkClicked('faq') } offset={ () => 100 } href="#faq" className={ activeLink === 'faq' ? 'active' : '' }>FAQ</AnchorLink>

						<ul className="nav-social-media-icons pl-0 pb-0">
							<li><a href={socialMediaLinks.facebook} target="_blank" rel="noopener noreferrer"><FacebookIcon /></a></li>
							<li><a href={socialMediaLinks.discord} target="_blank" rel="noopener noreferrer"><DiscordIcon /></a></li>
							<li><a href={socialMediaLinks.twitter} target="_blank" rel="noopener noreferrer"><TwitterIcon /></a></li>
						</ul>
					</Nav>
				</Navbar.Collapse>
				<Nav id="nav-social-media" className="navbar-right">
					<ul className="nav-social-media-icons text-right pl-0 pb-0">
						<li>Follow us on</li>
						<li><a href={socialMediaLinks.facebook} target="_blank" rel="noopener noreferrer"><FacebookIcon /></a></li>
						<li><a href={socialMediaLinks.discord} target="_blank" rel="noopener noreferrer"><DiscordIcon /></a></li>
						<li><a href={socialMediaLinks.twitter} target="_blank" rel="noopener noreferrer"><TwitterIcon /></a></li>
					</ul>
				</Nav>
		</Navbar>
	)
}

export { Navigation };